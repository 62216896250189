module.exports = [
  {
    name: "Real Estate",
    endpoint: "real-estate",
  },
  {
    name: "Mortgage",
    endpoint: "mortgage",
  },
  {
    name: "Productivity",
    endpoint: "productivity",
  },
  {
    name: "Sales Tips",
    endpoint: "sales",
  },
];
